import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './contact.css';
import { AiOutlineContacts } from 'react-icons/ai';



const Contact = () => {

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_dahmF15', 'template_dahmF15', form.current, 'F4nXiCt8ORGaXD2FL')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  }

  return (
    <>


      <section id='contact'>
        <h1 className='title'>Contact</h1>
        <div className='container'>

          <form ref={form} onSubmit={sendEmail}>

            <div className='col-8 pt-5 mx-auto'>
              <input type="text" className="Name" placeholder='Enter your name' name='name'></input>
            </div>

            <div className='col-8 form-group pt-2 mx-auto'>
              <input type="email" name="email" placeholder='Enter your email' className='Email'></input>
            </div>

            <div className='col-8 form-group pt-2 mx-auto'>
              <input type="text" name="subject" placeholder='Write the subject' className='Subject'></input>
            </div>

            <div className='col-12 form-group pt-2 mx-auto'>
              <textarea type="text" id="" rows="8" name="message" placeholder='Write your message' className='Message'></textarea>
            </div>

            <div className='col-12 form-group pt-3 mx-auto'>
              <input type="submit" value="Send Message" className="btn"></input>
            </div>

 
          </form>
        </div>

      </section>
    </>
  );
};

export default Contact;