import React, { useState } from 'react';
import './home.css';
import Navbar from '../../Components/navbar/navbar';
import Sidebar from '../../Components/sidebar/sidebar';
import Arabic from '../../imgs/ArabicNEW.jpg';
import English from '../../imgs/EnglishNEW.jpg';
import Cardscert from '../../Components/cardscert/cardscert';
import Cardspro from '../../Components/cardspro/cardspro';
import Contact from '../contact/contact';


const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>


            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />


            <section class='main' id='/'>
                <div>
                    <h2>Abdulrahman Eid Nami Alharbi here.<br />
                        <span>Front-End Developer.</span></h2>
                    <h3>I received a bachelor's degree in computer science from Taibah University.<br />
                        I have knowledge of HTML, CSS, JavaScript, ReactJS, MongoDB, and NodeJS, and I find website design and development to be fascinating.
                    </h3>
                    <a href={Arabic} class="main-btn" target={"_blank"}>here is Arabic CV</a>
                    <a href={English} class="main-btn" target={"_blank"}>here is English CV</a>

                    <div class="social-icons">
                        <a class="link" href="https://www.linkedin.com/in/abdulrahman-abdulrahman-163504217/" target={"_blank"}><i class="fab fa-linkedin"></i></a>
                        <a class="twitter" href="https://twitter.com/devabdulrahman"><i class="fab fa-twitter"></i></a>
                        <a class="github" href="https://github.com/dahmF15" target={"_blank"}><i class="fab fa-github"></i></a>
                    </div>
                </div>
            </section>


            <Cardscert />

            <Cardspro />

            <Contact />

        </>
    );
}
export default Home;