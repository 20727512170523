import React from 'react'
import HTML from '../../Certificants/HTML.jpg';
import Java from '../../Certificants/Java.png';
import Python from '../../Certificants/python.jpeg';
import Tr from '../../Certificants/Training.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './cardscert.css';




export default function CardsCert() {
    return (
        <section class="cards" id="certificates">

        <h2 class="title">Certificates</h2>
        <div class="content">

            <div class="card">
                <div class="icon">
                    <Zoom>
                <img src={HTML} className='image'/>
                </Zoom>
                </div>
                <div class="info">
                    <h3>HTML</h3>
                </div>
            </div>

            <div class="card">
                <div class="icon">
                    <Zoom>
                <img src={Java} className='image'/>
                </Zoom>
                </div>
                <div class="info">
                    <h3>Java</h3>
                </div>
            </div>

            <div class="card">
                <div class="icon">
                    <Zoom>
                <img src={Python} className='image'/>
                </Zoom>
                </div>
                <div class="info">
                    <h3>Python</h3>
                </div>
            </div>

            <div class="card">
                <div class="icon">
                    <Zoom>
                <img src={Tr} className='image'/>
                </Zoom>
                </div>
                <div class="info">
                    <h3>Training</h3>
                </div>

                
            </div>

            </div>
    </section>

    )
}
