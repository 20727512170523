import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/home/home';
import GProject from './Pages/GProject/GProject';
import Certificates from './Components/cardscert/cardscert';
import Projects from './Components/cardspro/cardspro';
import Contact from './Pages/contact/contact';





function App() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="certificates" element={<Certificates />} />
        <Route path="Projects" element={<Projects />} />
        <Route path="contact" element={<Contact />} />
        <Route path="GProject" element={<GProject />} />
      </Routes>
    </Router>
  );
}

export default App;