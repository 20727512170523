import React from 'react';
import LM from '../../Project-imgs/logo.png';
import './cardspro.css';



export default function CardsCert() {
    return (
        <section class="cards" id="Projects">

        <h2 class="title">Projects </h2>
        <div class="content">

            <div class="card">
                <div class="icon">
                    <a href="/GProject"><img src={LM} className='image'/></a>

                </div>
                <div class="info">
                    <h3>Graduation project</h3>
                </div>
            </div>


            </div>
    </section>

    )
}
