import React, { useState } from 'react';
import './GProject.css';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import pic1 from '../../Project-imgs/pic1.png';
import pic6 from '../../Project-imgs/pic6.png';
import pic4 from '../../Project-imgs/pic4.png';
import pic5 from '../../Project-imgs/pic5.png';
import pic2 from '../../Project-imgs/pic2.png';
import pic3 from '../../Project-imgs/pic3.png';
import logo from '../../Project-imgs/logo.png';
import { IoIosArrowBack } from 'react-icons/io'



const Projects = () => {


  return (
    <>



      <section class='GProjects'>
        <h2 class='title'>
          Graduation project
        </h2>

        <p class='description'>At this time, the Saudi Arabia Government aims to expand the tourism domain.
        The purpose of our project is to inform people about landmarks in Saudi Arabia and the methods we will use to create web applications.
        The result will be that most people will have a lot of information about landmarks in Saudi Arabia.
        like Historical places and Natural places. so the visitors will search about some places or just enter the website and read more about the places in Saudi Arabia.
        </p>

        <h3 className="Description">
          Our home page has two sections the first section containing simple vedio about Saudi Arabia.
        </h3>
        <Zoom>
            <img src={pic1} className="img"></img>
          </Zoom>
        
        <h3 className="Description"> In the second section we have group of cards that contain the recommendations for the
        amazing places you can visit it.</h3>

        <Zoom>
            <img src={pic6} className="img"></img>
          </Zoom>        

        <h3 className="Description">Visitors can add landmarks to our website by writing the name and description of the landmark.<br/>
        selecting the category for the landmark,
        and specifying the location on the Write page.<br/>
        In the end, visitors will publish it and send it to the administrators for approval or rejection.</h3>

        <Zoom>
            <img src={pic4} className="img"></img>
          </Zoom>

        <h3 className="Description">In landmarks page the visitors can see all the landmarks is aproval by the administrators.<br/>
        and can search by the name or category</h3>  
        <Zoom>
            <img src={pic5} className="img"></img>
          </Zoom>

         <h3 className="Description">When a visitor selects a landmark, he may learn more about it, 
         take action by commenting on it and rating it,
         or simply read the comments and view the ratings.
         </h3> 

         <Zoom>
            <img src={pic2} className="img"></img>
          </Zoom>

          <Zoom>
            <img src={pic3} className="img"></img>
          </Zoom>


          <h2 className="title"> our logo </h2>
          <Zoom>
            <img src={logo} className="img"></img>
          </Zoom>

          <a href='/' class="button"><IoIosArrowBack/></a>

      </section>

    </>
  );
};

export default Projects;