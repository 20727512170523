import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarItems } from './sidebarcss';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>

      <SidebarWrapper>
      <SidebarMenu>

      <SidebarItems>
        <SidebarLink to='/Certificates' >Certificates</SidebarLink>
      </SidebarItems>
      <SidebarItems>
        <SidebarLink to='/GProject'>GProject</SidebarLink>
      </SidebarItems>
      <SidebarItems>
        <SidebarLink to='/Contact'>Contact me</SidebarLink>
      </SidebarItems>

        </SidebarMenu>
      </SidebarWrapper>

    </SidebarContainer>
  )
}

export default Sidebar;