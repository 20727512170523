import React from 'react';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItems, NavLinks} from './navbarcss';
import { FaBars } from 'react-icons/fa';

const Navbar = ({ toggle }) => {
  return (

    <>
    
    <Nav>

    <NavbarContainer>

    <NavLogo to="/">Abdulrahman</NavLogo>

    <MobileIcon onClick={toggle}>
      <FaBars/>
    </MobileIcon>

    <NavMenu>
      <NavItems>
        <NavLinks to='certificates' >Certificates</NavLinks>
      </NavItems>
      <NavItems>
        <NavLinks to='Projects'>Projects</NavLinks>
      </NavItems>
      <NavItems>
        <NavLinks to='contact'>Contact me</NavLinks>
      </NavItems>
    </NavMenu>

    </NavbarContainer>

    </Nav>

    </>

  )
}

export default Navbar;